<script>
    import appConfig from "@/app.config";
    import { required } from "vuelidate/lib/validators";
    // import Select2 from 'v-select2-component';
import moment, { min } from 'moment';

    export default {
        props: ["areas", "shift", "location_id", "dyn_areas"],
        //  components:{
        //      Select2
        //  },

        data() {
            return {
                show: true,
                shift_names: [],
                isFull: true,
                timebreakshiftfrom: null,
                timebreakshiftto: null,
            };
        },
        validations: {
            shift: {
                name: {
                    required,
                },
                chunk: {
                    required,
                },
                from: {
                    required,
                },
                to: {
                    required,
                },
                desc: {
                    required,
                },
            },
        },

        watch: {
            //   isFull:{
            //     handler: function(newD,old){
            //         if(this.isFull == true){
            //             this.shift.chunk = 1;
            //         }
            //     }
            // },

            'shift.to'(newVal){
                    const format1 = "YYYY-MM-DD HH:mm:ss";
                    // this.timebreakshiftto = moment(this.shift.to).format(format1);
                    this.shift.utc_to=this.changeDateTimeToUTCZero(this.shift.to); 
                    this.shift.chunk =0;
                    this.shift.chunk =1;
                
            },
            'shift.chunk' :{
                handler : function(newVal){
                    if(this.shift.from && this.shift.to && newVal != null)
                    {
                        this.shift.shifttimebreak = [];
                        let intervalDiff=this.hoursTimeDiff(this.timebreakshiftfrom,this.timebreakshiftto)
                        let ratio= this.determineRatio(intervalDiff,this.shift.chunk);
                        let timeBreak=this.numbertoHours(ratio);
                        if(this.shift.chunk>1){
                            this.addHoursTime(this.timebreakshiftfrom,timeBreak,this.shift.chunk);
                        }else{
                            this.shift.shifttimebreak.push({from:this.changeDateTimeToUTCZero(this.shift.from),to:this.changeDateTimeToUTCZero(this.shift.to),type:'Full'});
                        }
                    }
                },deep : true,
            },

            areas: {
                handler: function (newD, old) {
                    if (this.location_id != null) {
                        // if (newD) {
                        //     newD.map((area) => {
                        //         area.roles = area.area_role;
                        //     });
                        // }
                    }
                },
                // immediate: true,
            },
            'shift.from': {
                handler: function (newD, old) {
                        if (newD) {
                            this.shift.day=this.getDayname(newD);
                        }
                        const format1 = "YYYY-MM-DD HH:mm:ss";
                        // this.timebreakshiftfrom = moment(this.shift.from).format(format1);
                        this.shift.utc_from=this.changeDateTimeToUTCZero(this.shift.from); 
                        this.shift.chunk =null;
                        this.shift.chunk =1;
                },
            },
        },
        mounted(){
            this.getShiftNames();
        },
        methods: {
        getShiftNames() {
            this.$axios.get('shift/name/index' , {params: {pagination : 0}}).then((response) => {
                let data = response.data.payload.paginated;
                this.shift_names = data;
            }).catch((error) => {
            this.handleErrorResponse(error.response, "error");
            });
        },
        hoursTimeDiff(startTime,endTime){
            var todayDate=this.getCurrentDate();  //  return in format YYYY-MM-DD 
            var startDate = new Date(`${startTime}`);
            var endDate = new Date(`${endTime}`);
            var timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

            var hh = Math.floor(timeDiff / 1000 / 60 / 60);   
            hh = ('0' + hh).slice(-2)
        
            timeDiff -= hh * 1000 * 60 * 60;
            var mm = Math.floor(timeDiff / 1000 / 60);
            mm = ('0' + mm).slice(-2)

            timeDiff -= mm * 1000 * 60;
            var ss = Math.floor(timeDiff / 1000);
            ss = ('0' + ss).slice(-2)
            
            // alert("Time Diff- " + hh + ":" + mm + ":" + ss);
            return {hh:hh , mm:mm , ss:ss};
        },

        determineRatio(intervalDiff, duration) {
            let totalHours=intervalDiff.hh+ ' : ' + intervalDiff.mm+ ' : ' + intervalDiff.ss;

            var totalHoursArray = totalHours.split(':');

            var numerator = parseInt(totalHoursArray[0])*3600 
                + parseInt(totalHoursArray[1])*60 
                + parseInt(totalHoursArray[2]);

            var res=numerator/duration;
            return res/3600

        },

        numbertoHours(value){
            var hours = Math.floor(value);
            var minutes = Math.round((value % 1)*100)/100;

            return {hours:hours, minutes:minutes};       

        },

        addHoursTime(startTime,breakAbleTime,chunks){
            this.shift.shifttimebreak=[];
            let from=startTime;
            const format1 = "YYYY-MM-DD HH:mm:ss";
            var todayDate=this.getCurrentDate();  //  return in format YYYY-MM-DD 
            for(let i=1;i<=chunks;i++){
                var dt = new Date(`${from}`);
                dt.setHours(dt.getHours() + breakAbleTime.hours);
                dt.setMinutes(dt.getMinutes() + breakAbleTime.minutes);
                let to =moment(dt).format(format1);
                this.shift.shifttimebreak.push({from:this.changeDateTimeToUTCZero(from),to:this.changeDateTimeToUTCZero(to),type:'Half'});
                from=to;
            }
        },
        
            AddformData() {
                this.areas.push({
                 id:null,
                 area_id: null,
                 roles: null,
                 area_role: [{
                     id:null,
                     count:null,
                     role_count:null,
                     remaining_slots:null,
                 }],
             });
            },
            addRole(role_index) {
                // this.areas.map((area) => {
                //     if (area.id == id) {
                //         area.area_role.push({
                //             role: null,
                //             count: null,
                //         });
                //     }
                // });
                this.areas[role_index].area_role.push({
                    role: null,
                    count: null,
                    role_count:null,
                    remaining_slots:null,
                });
            },

            deleteRow(index) {
                this.areas.splice(index, 1);
            },
            deleteRole(index, id) {
                this.areas.map((area) => {
                    if (area.id == id) {
                        area.area_role.splice(index, 1);
                    }
                });
            },

            showRole(e, id) {
                this.areas.map((area) => {
                    if (area.id == id) {
                        this.dyn_areas.map((d_area) => {
                            if (e.target.value == d_area.id) {
                                area.roles = d_area.area_role;
                            }
                        });
                    }
                });
            },
        },

        middleware: "authentication",
    };
</script>

<template>
    <div>
        <div class="row">
            <form ref="shiftForm">
                 <div class="col-md-12 mb-3">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="d-flex align-items-center gap-2">
                                    <!-- <div class="icon-wrapper mt-3 pt-1">
                                            <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                                        </div> -->
                                    <div class="form-field__wrapper w-100">
                                        <!-- <b-form-group id="input-group-2" label="Shift Name" label-for="input-2">
                                            <b-form-input id="input-2" v-model="shift.name" placeholder="Enter shift Name" required></b-form-input>
                                        </b-form-group> -->
                                        <label>Shift Name</label>

                                        <!-- <select v-model="shift.name"  id="shift_name" class="form-select">
                                            <option :value="shift_name" v-for="(shift_name, index) in shift_names" :key="index">
                                                {{ shift_name }}
                                            </option>
                                        </select> -->

                                        <b-input-group >
                                            <select v-model="shift.name"  id="shift_name" class="form-select" >
                                                <option :value="shift_name.name" v-for="(shift_name, index) in shift_names" :key="index">
                                                    {{ shift_name.name }}
                                                </option>
                                            </select>
                                            <b-input-group-prepend is-text class="w-25">
                                                 <b-form-input id="color" v-model="shift.label_color" type="color"></b-form-input>
                                            </b-input-group-prepend>
                                        </b-input-group>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="d-flex flex-md-row flex-column gap-3">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="align-items-center d-flex gap-2">
                                    <!-- <div class="icon-wrapper mt-3 pt-1">
                                                    <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                                                </div> -->
                                    <div class="form-field__wrapper w-100">
                                        <b-form-group id="example-from" label="From" label-for="time">
                                            <b-form-input id="time" v-model="shift.from" :min="changeDateTimeToLocalFormats(Date())"  type="datetime-local"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <div class="align-items-center d-flex gap-2">
                                    <!-- <div class="icon-wrapper mt-3 pt-1">
                                                    <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                                                </div> -->
                                    <div class="form-field__wrapper w-100 mt-4">
                                        <!-- <b-form-group id="example-chunk" label="Shift Break Count" label-for="time">
                                            <b-form-input id="time" v-model="shift.chunk" min="0" max="5" type="number"></b-form-input>
                                        </b-form-group> -->

                                        <!-- <b-input-group>
                                            <b-input-group-prepend is-text>
                                                <b-form-checkbox  :value='true' v-model="isFull" checked plain >Full Shift</b-form-checkbox>
                                            </b-input-group-prepend>
                                            <b-form-input aria-label="Text input with checkbox"  min="1" max="5" type="number" placeholder="Enter Chunks" v-model="shift.chunk" :disabled="isFull == true"></b-form-input>
                                        </b-input-group> -->

                                    </div>
                                </div>
                            </div>

                             <!-- <div class="col-md-4">
                                 <b-form-group id="example-color" label="Label" label-for="color" class="mb-3">
                                     <b-form-input id="color" v-model="shift.label_color" type="color"></b-form-input>
                                 </b-form-group>
                               
                            </div> -->
                        </div>
                    </div>
                    <div class="w-100">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="align-items-center d-flex gap-2">
                                    <!-- <div class="icon-wrapper pt-1">
                                                    <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                                                </div> -->
                                    <div class="form-field__wrapper w-100">
                                        <b-form-group id="example-date" label="To" label-for="time" class="mb-3">
                                            <b-form-input id="time" v-model="shift.to" :min="changeDateTimeToLocalFormats(shift.from)" type="datetime-local"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12 mb-3">
                    <div class="d-flex align-items-center gap-2">
                         <div class="icon-wrapper mt-3 pt-1">
                                            <img src="@/assets/images/profile-manager/icon-09.png" width="21" alt="" />
                                        </div> 
                        <div class="form-field__wrapper w-100">
                            <b-form-group id="desc" label="Description" label-for="input-2">
                                <textarea class="form-control" v-model="shift.desc" :maxlength="225" rows="3" placeholder="Write Description"></textarea>
                            </b-form-group>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-12 mb-3" v-if="dyn_areas.length>0">
                 
                        <div class="bg-light p-3 mb-3 rounded" v-for="(field,index) in areas" :key="index">
                            <div class="area-grid align-items-end mb-3">
                                <div class="grid-01">
                                    <!-- <div class="icon-wrapper mt-3 pt-1">
                                                        <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                                                    </div> -->
                                    <div class="form-field__wrapper w-100">
                                        <label>Area</label>
                                        <select id="work_area" @change="showRole($event,field.id)" v-model="field.id" class="form-select">
                                            <option :value="area.id" v-for="(area,index) in dyn_areas" :key="index">{{area.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="grid-02 text-end">
                                    <div class="mt-3">
                                        <button type="button" class="border-0 btn bg-white" @click="deleteRow(index)">Delete</button>
                                    </div>
                                </div>
                            </div>
                            <div class="d-grid mb-3 role-grid align-items-end" v-for="(area_role,role_index) in field.area_role" :key="role_index">
                                <div class="grid-01">
                                    <!-- <div class="icon-wrapper mt-3 pt-1">
                                            <img src="@/assets/images/profile-manager/user-icon.png" width="21" alt="" />
                                        </div> -->
                                    <div class="form-field__wrapper w-100">
                                        <label>Role</label>
                                        <select id="role" v-model="area_role.id" class="form-select">
                                            <option :value="role.id" v-for="(role,index) in field.roles" :key="index">{{role.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="grid-02 pe-0">
                                    <b-form-group id="example-count" label="Count" label-for="count" class="">
                                        <b-form-input id="count" v-model="area_role.count" type="number" required></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="delete-fields grid-03 text-end">
                                    <div class="mt-md-3 mt-2">
                                        <button type="button" class="border-0 btn bg-white" @click="deleteRole(role_index,field.id)">Delete</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="">
                                    <div class="add-fields">
                                        <input type="button" class="mt-3 mt-lg-0 border-0 btn bg-white rounded" value="Add Role"  @click="addRole(index,field.id)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                            <div class="add-fields">
                                <button type="button" class="mt-3 mt-lg-0 border-0 btn bg-light" @click="AddformData()">Add Area</button>
                            </div>
                        </div>
                    </div>
                </div>

                <b-form-group>
                    <b-form-checkbox v-model="shift.shift_type" value="Premium" unchecked-value="Non_Premium" class="mb-3" checked plain>Premium</b-form-checkbox>
                </b-form-group>
                <div class="col-md-2 ms-4" v-if="shift.shift_type=='Premium'">
                    <div class="input-group mb-3">
                        <span class="input-group-text">$</span>
                        <input type="number" v-model="shift.bonus" placeholder="00" class="form-control" aria-label="Amount (to the nearest dollar)" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<style scoped></style>
